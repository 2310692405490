require('waypoints/lib/noframework.waypoints');

function saveAdDataToStorage(zoneId, data) {
    let ads = getAdDataInStorage() || {};
    ads[zoneId] = data;
    sessionStorage.setItem('ads', JSON.stringify(ads));
}

function getAdDataInStorage() {
    try {
        let ads = JSON.parse(sessionStorage.getItem('ads')) || {};
        return ads;
    } catch (error) {
        return false;
    }
}

function getAdData(zoneId, zoneUrl) {
    const ads = getAdDataInStorage();
    // Check to see if the zone data exists 
    if (ads.hasOwnProperty(zoneId)) {
        // Does so is cached.
        // Create a promise to look like a live data fetch
        // and return the ads data
        return new Promise(function (resolve, reject) {
            resolve(ads[zoneId]);
        });
    }
    return fetch(zoneUrl)
        .then(function (response) {
            return response.json().then(function (json) {
                saveAdDataToStorage(zoneId, json.data.length > 0 ? json.data : false);
                return json.data;
            });
        }).catch(function (error) { console.error(error); });
}

window.loadAds = function (zoneUrl, baseUrl, zoneId) {
    getAdData(zoneId, zoneUrl).then(function (data) {
        var zone = document.getElementById('zone-' + zoneId);
        if (data && data.length > 0) {
            var ad = data[0];
            var sourceImageUrl = [baseUrl, 'view', 'optimised', ad.id].join('/');
            var imageUrl = [baseUrl, 'view', zoneId, ad.id].join('/');
            var clickUrl = [baseUrl, 'click', zoneId, ad.id].join('/');

            var link = document.createElement('a');
            link.href = clickUrl;
            link.title = ad.alt;
            link.target = ad.target;
            link.rel = "nofollow";

            var picture = document.createElement('picture');

            // Source for alternative
            var source = document.createElement('source');
            source.srcset = sourceImageUrl;

            // Img for main
            var img = document.createElement('img');
            img.src = imageUrl;
            img.width = ad.width;
            img.height = ad.height;
            img.alt = ad.alt;
            img.title = ad.alt;

            picture.appendChild(source);
            picture.appendChild(img);

            link.appendChild(picture);

            zone.appendChild(link);
        }
    });
}
